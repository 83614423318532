

































































































































import moment from 'moment'
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { ExamController, DropDownController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'

@Component({
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class StudentExamPointsRemark extends Vue {
  private currentTeacherType: Array<any> = []

  public leftDisabled = true
  public rightDisabled = true
  private examStatus: Number = 0
  private selectedTeacher: any = {}
  private schoolYearIdTransfer: any
  private examIdTransfer: any
  private examId: any = ''
  private sectionIdTransfer: any
  private houseIdTransfer: any
  private studentId: any = ''
  private data: any = []
  private dataStudentsInfo: any = []
  private schoolYearId: any = null
  private schoolYearList: Array<any> = []
  private exams: any = []

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    this.schoolYearIdTransfer = Number(this.$route.query.schoolYearId)
    this.examIdTransfer = Number(this.$route.query.examId)
    this.sectionIdTransfer = Number(this.$route.query.sectionId)
    this.houseIdTransfer = Number(this.$route.query.houseId)
    this.studentId = Number(this.$route.query.studentId)
    if (!this.sectionIdTransfer) this.sectionIdTransfer = undefined
    if (!this.houseIdTransfer) this.houseIdTransfer = undefined
    this.getStudentsInfo()
    this.getSchoolYearList()
  }

  private created(): void {
    this.$nextTick(() => {
      this.schoolYearIdTransfer = Number(this.$route.query.schoolYearId)
      this.examIdTransfer = Number(this.$route.query.examId)
      this.sectionIdTransfer = Number(this.$route.query.sectionId)
      this.houseIdTransfer = Number(this.$route.query.houseId)
      this.studentId = Number(this.$route.query.studentId)
      if (!this.sectionIdTransfer) this.sectionIdTransfer = undefined
      if (!this.houseIdTransfer) this.houseIdTransfer = undefined
      this.getStudentsInfo()
      this.getSchoolYearList()
    })
  }

  private reset(): void {
    this.examId = undefined
    this.schoolYearId = undefined
    this.data = {}
  }

  private handleClick(e): void {
    this.selectedTeacher = this.data.teacherComments.filter(item => item.teacherType === e.key)[0]
  }

  private moveLeft(): void {
    if (this.leftDisabled) return
    let studentListDom = this.$refs.studentList as any
    let tmpLeft = studentListDom.scrollLeft - 505
    studentListDom.scrollLeft = tmpLeft
  }

  private moveRight(): void {
    if (this.rightDisabled) return
    let studentListDom = this.$refs.studentList as any
    let tmpLeft = studentListDom.scrollLeft + 505
    studentListDom.scrollLeft = tmpLeft
  }

  private getStudentsInfo(): void {
    const examStudentQuery = {
      examId: this.examIdTransfer,
      sectionId: this.sectionIdTransfer,
      houseId: this.houseIdTransfer,
      pageCurrent: 1,
      // pageSize为0时表示不分页
      pageSize: 0,
    }
    ExamController.getExamStudents(examStudentQuery)
      .then(res => {
        this.dataStudentsInfo = res.data.items
        this.dataStudentsInfo.forEach(student => {
          student.active = false
          if (student.studentId === this.studentId) {
            student.active = true
          }
        })
        for (let i = 0; i < this.dataStudentsInfo.length; i++) {
          this.$set(this.dataStudentsInfo[i], 'active', false)
          if (this.dataStudentsInfo[i].studentId === this.studentId) {
            this.$set(this.dataStudentsInfo[i], 'active', true)
          }
        }
        this.$nextTick(() => {
          const studentDom = this.$refs['student-' + this.studentId][0] as any
          studentDom.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'smooth',
          })
          this.onScroll(null)
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  public selectStudent(studentId: string): void {
    const schoolYearId = (this.schoolYearIdTransfer || '').toString()
    const examId = (this.examIdTransfer || '').toString()
    const houseId = (this.houseIdTransfer || '').toString()
    const sectionId = (this.sectionIdTransfer || '').toString()
    this.$router
      .replace({
        name: 'studentExamPointsRemark',
        query: { schoolYearId, examId, houseId, sectionId, studentId },
      })
      .catch(err => {})
  }

  private getExamDropdown(schoolYearId): void {
    ExamController.getExamDropdownByStudent(schoolYearId, this.studentId)
      .then(res => {
        this.exams = res.data
        this.examId = this.examIdTransfer
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private mounted() {
    ;(this.$refs.studentList as any).addEventListener('scroll', this.onScroll, false)
  }

  private beforeDestroy() {
    ;(this.$refs.studentList as any).removeEventListener('scroll', this.onScroll, false)
  }

  private onScroll(e) {
    let listDom = this.$refs.studentList as any
    this.leftDisabled = listDom.scrollLeft === 0
    this.rightDisabled = false
    if (listDom.scrollLeft + listDom.clientWidth === listDom.scrollWidth) {
      this.rightDisabled = true
    }
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList().then(res => {
      this.schoolYearList = res.data
      this.schoolYearId = this.schoolYearIdTransfer
      this.getExamDropdown(this.schoolYearId)
    })
  }

  private getData(): void {
    ExamController.getTranscript(this.examId, this.studentId)
      .then(res => {
        this.data = res.data
        this.selectedTeacher = JSON.parse(JSON.stringify(this.data.teacherComments[0]))
        this.currentTeacherType = [this.selectedTeacher.teacherType]
        this.data.transcriptItems = res.data.transcriptItems.map(item => {
          const stat = JSON.parse(JSON.stringify(item.gradings))
          if (JSON.stringify(stat) !== '{}') {
            for (let key in stat) {
              let target = this.data.items.filter(item => item.transcriptItemId === Number(key))[0]
              if (target.type === 'grading') {
                stat[key] = target.gradings.filter(item => item.level === stat[key])[0].description
              }
            }
          }
          return {
            ...item,
            ...stat,
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    let dynamicColumn = [] as any
    if (this.data.items) {
      dynamicColumn = this.data.items.map(item => {
        let tmpDecription =
          item.description > 20 ? item.description.slice(0, 20) + '...' : item.description
        return {
          key: item.transcriptItemId,
          title: tmpDecription,
          align: 'left',
          filterDropdown: item.type === 'centesimal', // 自定义的列筛选功能，我们占位为信息提示Icon的位置
          filterIcon: () => {
            return this.$createElement(
              'a-tooltip',
              {
                props: {
                  title: `(${item.from}-${item.to}${
                    item.integral ? this.$t('common.integral') : ''
                  })`,
                },
              },
              [
                this.$createElement('a-icon', {
                  props: {
                    type: 'question-circle',
                  },
                }),
              ]
            )
          },
          scopedSlots: { customRender: 'dynamic' + item.transcriptItemId },
        }
      })
    }
    return [
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('exam.subject'),
        fixed: 'left',
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'subject' },
      },
      {
        dataIndex: 'course',
        key: 'course',
        fixed: 'left',
        width: 200,
        title: this.$t('exam.courseClass'),
        ellipsis: true,
        scopedSlots: { customRender: 'course' },
      },
      {
        dataIndex: 'teachers',
        key: 'teachers',
        title: this.$t('exam.teachers'),
        fixed: 'left',
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'teachers' },
      },
      ...dynamicColumn,
      {
        dataIndex: 'comment',
        key: 'comment',
        title: this.$t('exam.comment'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'comment' },
        width: 150,
      },
    ]
  }
}
