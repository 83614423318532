






































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ExamController, DropDownController, CourseController } from '@/services/request.service'
import { wordNumFormat } from '@/utils/format'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import debounce from 'lodash/debounce'
import { createPagination } from '@/constant/constant'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  name: 'ExamRemarkMgmt',
  filters: {
    moreWordFormat(value: any) {
      return wordNumFormat(value, 15)
    },
  },
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class ExamRemarkMgmt extends Vue {
  private modalHandleNextOkLoading: boolean = false
  private modalHandleOkLoading: boolean = false
  private editTeacher: any = {}
  private batchShowFlag: Array<any> = []
  private modalCommentShow: any = ''
  private selectedStudents: Array<any> = []
  private batchFlag: boolean = false
  private modalVisible: boolean = false
  private commentSettings: Array<any> = []
  private selectedRowKeys: Array<any> = []
  private spinning: boolean = true
  private loading: boolean = false
  private showData: any = []
  private editComentFlag: boolean = false
  private currentTeacher: Array<any> = []
  private showCommentNow: any = {}
  private studentId: any
  private selectedComments: Array<any> = []
  private examStatus: Number = 0
  private saveLoading: boolean = false
  private editFlag: boolean = false
  private houseList: any = []
  private sectionList: any = []
  private data: any = []
  private targetIsLast: boolean = false
  private schoolYearList: Array<any> = []
  private schoolYearId: any = ''
  private exams: Array<any> = []
  private filter: any = {
    schoolYearId: undefined,
    examId: undefined,
    sectionId: undefined,
    houseId: undefined,
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private get columns(): Array<any> {
    let dynamicColumn = [] as any
    if (this.commentSettings) {
      dynamicColumn = this.commentSettings.map((item, index) => {
        return {
          key: item.teacherType,
          slots: { title: 'commentTitle' + item.teacherType },
          scopedSlots: { customRender: 'dynamic' + item.teacherType },
          align: 'left',
          width: 220,
        }
      })
    }
    return [
      {
        title: this.$t('exam.name'),
        key: 'name',
        scopedSlots: { customRender: 'name' },
        fixed: 'left',
        width: 150,
        align: 'left',
        ellipsis: true,
      },
      {
        title: this.$t('exam.code'),
        dataIndex: 'studentNum',
        scopedSlots: { customRender: 'code' },
        key: 'studentNum',
        fixed: 'left',
        width: 100,
      },
      {
        dataIndex: 'className',
        key: 'className',
        title: this.$t('exam.className'),
        scopedSlots: { customRender: 'className' },
        fixed: 'left',
        width: 120,
        ellipsis: true,
      },
      ...dynamicColumn,
    ]
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (this.editComentFlag) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private created(): void {
    this.$nextTick(() => {
      this.getSchoolYearList()
      this.getHouseGroupListAll()
    })
  }

  private modalHandleOk(): void {
    this.modalHandleOkLoading = true
    const request = {
      studentId: this.selectedStudents.map(item => item.studentId),
      teacherType: Number(this.editTeacher.teacherType),
      comment: this.modalCommentShow,
      examId: this.filter.examId,
    }
    ExamController.saveStudentComments(request)
      .then(res => {
        this.refresh()
        this.modalVisible = false
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.modalHandleOkLoading = false
      })
  }

  private finishAndNextButton(): void {
    // if (this.modalCommentShow === '') {
    //   this.$message.warning(this.$tc('exam.commentTip'))
    //   return
    // }
    this.modalHandleNextOkLoading = true
    const request = {
      studentId: this.selectedStudents.map(item => item.studentId),
      teacherType: Number(this.editTeacher.teacherType),
      comment: this.modalCommentShow,
      examId: this.filter.examId,
    }
    ExamController.saveStudentComments(request)
      .then(res => {
        console.log('反馈:', res)
        this.selectedStudents[0].teacherComments[
          this.editTeacher.teacherType
        ].comment = this.modalCommentShow
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].studentId === this.selectedStudents[0].studentId) {
            let target = i
            do {
              target++
              if (target === this.data.length) {
                target = 0
              }
            } while (!this.data[target].teacherComments[this.editTeacher.teacherType].editable)
            this.targetIsLast = this.validateIsLast(i + 1)
            this.selectedStudents = []
            this.selectedStudents.push(this.data[target])
            this.modalCommentShow = this.data[target].teacherComments[
              this.editTeacher.teacherType
            ].comment
            break
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.modalHandleNextOkLoading = false
      })
  }

  private modalHandleCancel(): void {
    this.modalVisible = false
  }

  private addComment(record, teacher, index): void {
    this.editTeacher = teacher
    this.selectedStudents = []
    this.batchFlag = false
    this.selectedStudents.push(record)
    this.modalCommentShow = record.teacherComments[teacher.teacherType].comment
    this.modalVisible = true
    this.targetIsLast = this.validateIsLast(index)
  }

  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.data.length) {
        return true
      }
    } while (!this.data[index].teacherComments[this.editTeacher.teacherType].editable)
    return false
  }
  private addCommentBatch(teacher): void {
    this.batchFlag = true
    this.editTeacher = teacher
    this.selectedStudents = []
    this.data.forEach(item => {
      if (
        this.selectedRowKeys.indexOf(item.studentId) !== -1 &&
        (item.teacherComments
          ? item.teacherComments[teacher.teacherType]
            ? item.teacherComments[teacher.teacherType].editable
            : false
          : false)
      ) {
        this.selectedStudents.push(item)
      }
    })
    this.modalCommentShow = ''
    this.modalVisible = true
  }

  private getCommentSettings(examId): void {
    ExamController.getCommentSettings(examId)
      .then(res => {
        console.log('动态列数据:', res.data)
        this.commentSettings = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  // TODO: 优化全选
  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
    if (!this.selectedRowKeys.length) {
      for (let i = 0; i < this.batchShowFlag.length; i++) {
        this.batchShowFlag[i] = false
      }
    } else {
      this.selectedStudents = []
      this.data.forEach(item => {
        if (this.selectedRowKeys.indexOf(item.studentId) !== -1) {
          this.selectedStudents.push(item)
        }
      })
      for (let i = 0; i < this.selectedStudents.length; i++) {
        for (let key in this.selectedStudents[i].teacherComments) {
          if (this.selectedStudents[i].teacherComments[key].editable) {
            this.batchShowFlag[this.selectedStudents[i].teacherComments[key].teacherType] = true
          }
        }
      }
    }
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private handleSection(): void {
    ExamController.getSectionDropDownByExamComment(this.filter.examId)
      .then(res => {
        this.sectionList = res.data
        this.filter.sectionId = res.data[0].key
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.getData())
  }

  private handleChangeSchoolYear = debounce(this.fetchHandleChange, 200)

  private fetchHandleChange(): void {
    if (!this.filter.schoolYearId) return
    ExamController.getExamDropdownByExamComment(this.filter.schoolYearId)
      .then(res => {
        if (res.data.length !== 0) {
          this.filter.examId = res.data[0].key
          this.exams = res.data
          this.changeExam()
        } else {
          this.filter.examId = undefined
          this.filter.sectionId = undefined
          this.filter.houseId = undefined
          this.exams = []
          this.data = []
          this.sectionList = []
          this.examStatus = 0
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeExam(): void {
    const key = this.filter.examId
    this.examStatus = this.exams.filter(item => item.key === key)[0].extraValue.status
    this.getCommentSettings(this.filter.examId)
    this.handleSection()
  }

  private cancel(): void {
    this.editComentFlag = false
  }

  private reset(): void {
    this.filter = {
      schoolYearId: undefined,
      examId: undefined,
      sectionId: undefined,
      houseId: undefined,
    }
    this.showData = []
    this.selectedComments = []
    this.showCommentNow = {}
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private saveComments(): void {
    this.saveLoading = true
    if (this.showCommentNow.comment === '') {
      this.$message.warning(this.$tc('exam.commentNotNull'))
      this.saveLoading = false
      return
    }
    const request = {
      studentId: this.studentId,
      examId: this.filter.examId,
      ...this.showCommentNow,
    }
    ExamController.saveStudentComments(request)
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
        this.editComentFlag = false
        this.refresh()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.editComentFlag = false
        this.saveLoading = false
      })
  }

  private editComent(): void {
    this.editComentFlag = true
  }

  private viewDetail(id): void {
    if (this.editComentFlag) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.editComentFlag = false
          this.viewDetail(id)
        },
        onCancel: () => {
          return
        },
      })
    } else {
      const schoolYearId = (this.filter.schoolYearId || '').toString()
      const examId = (this.filter.examId || '').toString()
      const houseId = (this.filter.houseId && this.filter.houseId.length
        ? this.filter.houseId[1]
        : null || ''
      ).toString()
      const sectionId = (this.filter.sectionId || '').toString()
      const studentId = (id || '').toString()
      this.$router
        .push({
          name: 'studentExamPointsRemark',
          query: { schoolYearId, examId, houseId, sectionId, studentId },
        })
        .catch(err => {})
    }
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get currentSchoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }

  // @Watch('currentSchoolYearId',{immediate: true})
  // private onSchoolYearIdChange(val):void {
  //   this.filter.schoolYearId = (this.schoolYearList.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
  //   this.handleChangeSchoolYear()
  // }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getHouseGroupListAll(): void {
    DropDownController.getHouseGroupListAll()
      .then(res => {
        this.houseList = res.data.map(item => {
          return {
            ...item,
            subOptions: item.houseGroups.map(it => {
              return {
                houseId: it.houseGroupId,
                houseName: it.name,
              }
            }),
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList().then(res => {
      this.schoolYearList = res.data
      this.filter.schoolYearId = (res.data[0] || {}).key
      // this.filter.schoolYearId = (res.data.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
      this.handleChangeSchoolYear()
    })
  }
  // 根据学年和学院获取总的数据，包括学生，考试，年级，并触发筛选函数
  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    const examStudentQuery = {
      examId: this.filter.examId,
      sectionId: this.filter.sectionId,
      houseId: this.filter.houseId ? this.filter.houseId[1] : undefined,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }
    if (!examStudentQuery.examId) return
    this.loading = true
    ExamController.getExamStudents(examStudentQuery)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'examRemark'
  }
}
