











































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ExamController, DropDownController, CourseController } from '@/services/request.service'
import moment from 'moment'
import { createElement } from '@fullcalendar/core'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import debounce from 'lodash/debounce'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class Exam extends Vue {
  private batchFlag: boolean = false
  private modalCommentShow: any = ''
  private selectedStudents: Array<any> = []
  private selectedRowKeys: Array<any> = []
  private validateStatusScore: Array<any> = []
  private errorMsgScore: Array<any> = []
  private examStatus: Number = 0
  private modalVisible: boolean = false
  private saveLoading: boolean = false
  private editFlag: boolean = false
  private exams: Array<any> = []
  private schoolYearList: Array<any> = []
  private data: any = {}
  private dataCopy: any = {}
  private document = document
  private courses: Array<any> = []
  private loading: boolean = false
  private targetIsLast: boolean = true
  private filter: any = {
    schoolYearId: undefined,
    examId: undefined,
    courseId: undefined,
  }
  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (this.editFlag) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private created(): void {
    this.getSchoolYearList()
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'examPoints'
  }

  private get columns(): Array<any> {
    let dynamicColumn = [] as any
    if (this.data.transcriptItem) {
      dynamicColumn = this.data.transcriptItem.map(item => {
        let tmpDecription =
          item.description > 20 ? item.description.slice(0, 20) + '...' : item.description
        return {
          key: item.transcriptItemId,
          title: tmpDecription,
          align: 'left',
          filterDropdown: item.type === 'centesimal', // 自定义的列筛选功能，我们占位为信息提示Icon的位置
          filterIcon: () => {
            return this.$createElement(
              'a-tooltip',
              {
                props: {
                  title: `(${item.from}-${item.to}${
                    item.integral ? this.$t('common.integral') : ''
                  })`,
                },
              },
              [
                this.$createElement('a-icon', {
                  props: {
                    type: 'question-circle',
                  },
                }),
              ]
            )
          },
          scopedSlots: { customRender: 'dynamic' + item.transcriptItemId },
        }
      })
    }
    if (this.data.comment) {
      const comment = {
        dataIndex: 'comment',
        key: 'comment',
        slots: { title: 'commentTitle' },
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: 'comment' },
      }
      dynamicColumn.push(comment)
    }

    return [
      {
        key: 'name',
        title: this.$t('exam.name'),
        scopedSlots: { customRender: 'name' },
        fixed: 'left',
        ellipsis: true,
        width: 150,
      },
      {
        dataIndex: 'code',
        key: 'code',
        title: this.$t('exam.code'),
        fixed: 'left',
        ellipsis: true,
        width: 100,
        scopedSlots: { customRender: 'code' },
      },
      {
        dataIndex: 'className',
        key: 'className',
        title: this.$t('exam.className'),
        fixed: 'left',
        ellipsis: true,
        width: 120,
        scopedSlots: { customRender: 'className' },
      },
      ...dynamicColumn,
    ]
  }

  private get currentSchoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }

  // @Watch('currentSchoolYearId',{immediate: true})
  // private onSchoolYearIdChange(val):void {
  //   this.filter.schoolYearId = (this.schoolYearList.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
  //   this.getExamDropdown(this.filter.schoolYearId)
  // }

  private reset(): void {
    if (this.editFlag) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.editFlag = false
          this.filter = {
            schoolYearId: undefined,
            examId: undefined,
            courseId: undefined,
          }
          this.data = {}
        },
        onCancel: () => {
          return
        },
      })
    } else {
      this.filter = {
        schoolYearId: undefined,
        examId: undefined,
        courseId: undefined,
      }
      this.data = {}
    }
  }

  private verifyScore(index, transcriptItemId): void {
    let target = this.data.transcriptItem.filter(
      item => item.transcriptItemId === transcriptItemId
    )[0]
    if (
      this.data.studentResults[index].gradings[transcriptItemId] < target.from ||
      this.data.studentResults[index].gradings[transcriptItemId] > target.to ||
      (target.integral === true &&
        this.data.studentResults[index].gradings[transcriptItemId] % 1 !== 0)
    ) {
      this.$set(this.validateStatusScore[index], transcriptItemId, 'error')
      this.$set(this.errorMsgScore[index], transcriptItemId, this.$t('exam.inputNumberOutRange'))
    } else {
      this.$set(this.validateStatusScore[index], transcriptItemId, '')
      this.$set(this.errorMsgScore[index], transcriptItemId, '')
    }
    console.log('this.validateStatusScore:', this.validateStatusScore)
  }

  private changeExam(key): void {
    if (this.editFlag) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.editFlag = false
          this.changeExam(key)
        },
        onCancel: () => {
          return
        },
      })
    } else {
      this.examStatus = this.exams.filter(item => item.key === key)[0].extraValue.status
      this.getDropDownInfo(key)
      // this.getData()
    }
  }

  private modalHandleOk(): void {
    this.selectedStudents.forEach(item => {
      item.comment = this.modalCommentShow
    })
    this.modalVisible = false
  }

  private finishAndNextButton(): void {
    this.selectedStudents[0].comment = this.modalCommentShow
    for (let i = 0; i < this.data.studentResults.length; i++) {
      if (this.data.studentResults[i].studentId === this.selectedStudents[0].studentId) {
        let target = i + 1
        this.selectedStudents = []
        if (target + 1 === this.data.studentResults.length) {
          this.targetIsLast = true
        }
        this.selectedStudents.push(this.data.studentResults[target])
        this.modalCommentShow = this.data.studentResults[target].comment
        break
      }
    }
  }

  private modalHandleCancel(): void {
    this.modalVisible = false
  }

  private addComment(record, index): void {
    this.batchFlag = false
    this.selectedStudents = []
    this.modalCommentShow = record.comment
    this.selectedStudents.push(record)
    this.modalVisible = true
    this.targetIsLast = index + 1 === this.data.studentResults.length
  }

  private addCommentBatch(): void {
    if (!this.selectedRowKeys.length) {
      this.$message.warning(this.$tc('exam.batchSelectError'))
      return
    }
    console.log('selectedRowKeys:', this.selectedRowKeys)
    console.log('data.studentResults:', this.data.studentResults)
    this.selectedStudents = []
    this.data.studentResults.forEach(item => {
      if (this.selectedRowKeys.indexOf(item.studentId) !== -1) {
        this.selectedStudents.push(item)
      }
    })
    this.modalCommentShow = ''
    this.batchFlag = true
    this.modalVisible = true
  }

  private cancel(): void {
    this.data = JSON.parse(JSON.stringify(this.dataCopy))
    this.editFlag = false
  }

  private saveExam(): void {
    for (let i = 0; i < this.validateStatusScore.length; i++) {
      for (let j = 0; j < this.validateStatusScore[i].length; j++) {
        if (this.validateStatusScore[i][j] === 'error') {
          return
        }
      }
    }
    this.saveLoading = true
    let studentResultRequest = this.data.studentResults
    let request = {
      examId: this.filter.examId,
      courseId: this.filter.courseId[1],
      studentResultRequest: studentResultRequest,
    }
    console.log('发送的数据:', request)
    ExamController.saveStudentResults(request)
      .then(res => {
        console.log('反馈是:', res.data)
        this.$message.success(this.$tc('common.saveSuccess'))
        this.editFlag = false
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.saveLoading = false
      })
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getDropDownInfo(examId): void {
    this.data.studentResults = []
    this.courses = []
    ExamController.getCourseCascade(examId)
      .then(res => {
        this.courses = res.data.map(item => {
          return {
            ...item,
            subOptions: item.subOptions.map(it => {
              return {
                key: it.key,
                value: it.value,
                enValue: it.enValue,
              }
            }),
          }
        })
        if (res.data[0]) {
          this.$set(this.filter, 'courseId', [res.data[0].key, res.data[0].subOptions[0].key])
          this.getData()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private edit(): void {
    this.editFlag = true
  }

  private viewDetail(id): void {
    this.editFlag = false
    const schoolYearId = (this.filter.schoolYearId || '').toString()
    const examId = (this.filter.examId || '').toString()
    const courseId = (this.filter.courseId[1] || '').toString()
    const studentId = (id || '').toString()
    this.$router.push({
      name: 'studentExamPoints',
      query: { schoolYearId, examId, courseId, studentId },
    })
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList().then(res => {
      this.schoolYearList = res.data
      this.filter.schoolYearId = (res.data[0] || {}).key
      // this.filter.schoolYearId = (res.data.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
      this.getExamDropdown(this.filter.schoolYearId)
    })
  }

  private fetchExamDropDown(schoolYearId): void {
    if (!schoolYearId) return
    if (this.editFlag) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.editFlag = false
          this.getExamDropdown(schoolYearId)
        },
        onCancel: () => {
          return
        },
      })
    } else {
      ExamController.getExamDropdown(schoolYearId)
        .then(res => {
          this.exams = res.data
          console.log('this.exams:', this.exams)
          if (this.exams.length !== 0) {
            this.examStatus = this.exams[0].extraValue.status
            this.filter.examId = this.exams[0].key
            this.getDropDownInfo(this.filter.examId)
          } else {
            this.filter.examId = undefined
            this.data = {}
            this.courses = []
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private getExamDropdown = debounce(this.fetchExamDropDown, 500)

  private getData(): void {
    if (this.editFlag) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.editFlag = false
          this.getData()
        },
        onCancel: () => {
          return
        },
      })
    } else {
      if (typeof this.filter.examId === 'undefined' || typeof this.filter.courseId === 'undefined')
        return
      this.data.studentResults = []
      this.loading = true
      const examId = this.filter.examId
      const courseId = this.filter.courseId[1]
      ExamController.getStudentsResults(examId, courseId)
        .then(res => {
          this.data = res.data
          console.log('原始数据:', res.data)
          this.data.studentResults = res.data.studentResults.map(item => {
            const stat = JSON.parse(JSON.stringify(item.gradings))
            if (JSON.stringify(stat) !== '{}') {
              for (let key in stat) {
                let target = this.data.transcriptItem.filter(
                  item => item.transcriptItemId === Number(key)
                )[0]
                if (target.type === 'grading') {
                  stat[key] = target.gradings.filter(
                    item => item.level === stat[key]
                  )[0].description
                }
              }
            }
            return {
              ...item,
              ...stat,
            }
          })
          this.data.studentResults.forEach(() => {
            this.validateStatusScore.push([])
            this.errorMsgScore.push([])
          })
          this.dataCopy = JSON.parse(JSON.stringify(this.data))
          console.log('处理后的数据:', this.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
